import React, { useEffect, useState } from "react";
import "./DashBaord.css";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Header from "../HeaderDashBoard/Header";
import SideBar from "../SideBar/SideBar";
import Footer from "../Footer/Footer";
import { Chart as ChartJS, LineElement, ArcElement, BarElement, PointElement, LineController, CategoryScale, LinearScale, Title, Tooltip, Legend } from 'chart.js';

// Register the necessary Chart.js components
ChartJS.register(
  LineElement,
  PointElement,
  LineController,
  CategoryScale,
  LinearScale,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
);
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { getproducttotalDatafetch } from "../Controller/Product";
import { getordertotalDatafetch, getlistOrderDatafetch } from "../Controller/Order";
import { getcategorytotalDatafetch } from "../Controller/Category";
import { getcoupontotalDatafetch } from "../Controller/Coupen";
import { getusertotalDatafetch, getuserlistDatafetch } from "../Controller/User";
import { getsubtotalDatafetch } from "../Controller/Subcategory";
import { getReviewDatafetch, getReviewDataListfetch } from "../Controller/Review";
ChartJS.register(
  CategoryScale,
  ArcElement,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function Dashboard() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const [totalproduct, setproducttotal] = useState([]);
  const [totalorder, setordertotal] = useState([]);
  const [totalcategory, setcategorytotal] = useState([]);
  const [totalcoupon, setcoupontotal] = useState([]);
  const [totaluser, setusertotal] = useState([]);
  const [totalsub, setsubtotal] = useState([]);
  const [totalReview, setReviewtotal] = useState([]);
  const [processingOrders, setProcessingOrders] = useState([]);
  const [pendingOrders, setPendingOrders] = useState([]);
  const [cancelOrders, setCancelOrders] = useState([]);
  const [deliveredOrders, setDeliveredOrders] = useState([]);
  const [selectedOption, setSelectedOption] = useState('year');
  const [totaluserlist, setusercharttotal] = useState([]);
  const [totalguestuser, setguestusertotal] = useState([]);
  const [ordermonthdata, setstatusOrders] = useState([]);
  const [ordermonthPricedata, settotalstatusOrders] = useState([]);
  const [totalOrdersByMonth, setTotalOrdersByMonth] = useState({});
  const [totalOrdersByWeek, setTotalOrdersByWeek] = useState({});
  const [activeOption, setActiveOption] = useState('year'); // State to track active option


  const getproducttotal = async () => {
    const options = {};
    try {
      const result = await getproducttotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setproducttotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getordertotal = async () => {
    const options = {};
    try {
      const result = await getordertotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setordertotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const getcategorytotal = async () => {
    const options = {};
    try {
      const result = await getcategorytotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setcategorytotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getcoupontotal = async () => {
    const options = {};
    try {
      const result = await getcoupontotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setcoupontotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getusertotal = async () => {
    const options = {};
    try {
      const result = await getusertotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setusertotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getuserlisttotal = async () => {
    const options = {};
    try {
      const result = await getuserlistDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        console.log(result.data.response.result.userList);
        const responseData = result.data.response.result.userList;
        const userData = responseData.filter(order => order.user_type === 'user');
        const gestuserData = responseData.filter(order => order.user_type === 'guest');
        console.log(userData.length);
        console.log(gestuserData.length);
        setusercharttotal(userData.length);
        setguestusertotal(gestuserData.length);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getsubcategorytotal = async () => {
    const options = {};
    try {
      const result = await getsubtotalDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setsubtotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const getReviewtotal = async () => {
    const options = {};
    try {
      const result = await getReviewDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const totalcount = result.data.response.result;
        setReviewtotal(totalcount);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const getReviewListtotal = async () => {
    const options = {};
    try {
      const result = await getReviewDataListfetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {

        const responseData = result.data.response.result.review;
        const reviewfData = responseData.filter(order => order.star === 5);
        const reviewffData = responseData.filter(order => order.star === 4);
        const reviewtData = responseData.filter(order => order.star === 3);
        const reviewttData = responseData.filter(order => order.star === 2);
        const reviewoData = responseData.filter(order => order.star === 1);
        setReviewfivetotal(reviewfData.length);
        setReviewfourtotal(reviewffData.length);
        setReviewthreetotal(reviewtData.length);
        setReviewtwototal(reviewttData.length);
        setReviewonetotal(reviewoData.length);
      } else {
        // localStorage.removeItem('TOKEN');
        // localStorage.removeItem('ADMIN-INFO');
        // navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };

  const getOrderdata = async (page, product_name) => {
    const options = {
      page: page,
      product_name: product_name,
    };

    try {
      const result = await getlistOrderDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.orders;
        console.log("responseData", responseData);
        const totalcount = result.data.response.result.totalCount;
        const processingOrdersData = responseData.filter(order => order.status === 'Processing');
        const pendingOrdersData = responseData.filter(order => order.status === 'Pending');
        const cancelOrdersData = responseData.filter(order => order.status === 'Canceled');
        const deliveredOrdersData = responseData.filter(order => order.status === 'Delivered');

        const totalPrice = processingOrdersData.reduce((sum, order) => {
          const price = parseFloat(order.price) || 0;
          const gst = parseFloat(order.gst) || 0;
          const shippingCharge = parseFloat(order.shipping_charge) || 0;

          const orderTotal = price + (price * (gst / 100)) + shippingCharge;

          return sum + orderTotal;
        }, 0);

        const totalPendingPrice = pendingOrdersData.reduce((sum, order) => {
          const price = parseFloat(order.price) || 0;
          const gst = parseFloat(order.gst) || 0;
          const shippingCharge = parseFloat(order.shipping_charge) || 0;

          const orderTotal = price + (price * (gst / 100)) + shippingCharge;

          return sum + orderTotal;
        }, 0);

        const totalCancelPrice = cancelOrdersData.reduce((sum, order) => {
          const price = parseFloat(order.price) || 0;
          const gst = parseFloat(order.gst) || 0;
          const shippingCharge = parseFloat(order.shipping_charge) || 0;

          const orderTotal = price + (price * (gst / 100)) + shippingCharge;

          return sum + orderTotal;
        }, 0);
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const currentYear = currentDate.getFullYear();
        const currentWeekStart = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));

        const ordersByMonthPrice = {};
        const ordersByWeekPrice = {};

        responseData.forEach(order => {
          const createdAt = new Date(order.created_date);
          const monthYear = `${createdAt.getFullYear()}-${(createdAt.getMonth() + 1).toString().padStart(2, '0')}`;
          const dayOfWeek = createdAt.getDay();
          const price = parseFloat(order.price) || 0;
          const gst = parseFloat(order.gst) || 0;
          const shippingCharge = parseFloat(order.shipping_charge) || 0;
          const orderTotal = price + (price * (gst / 100)) + shippingCharge;

          if (!ordersByMonthPrice[monthYear]) {
            ordersByMonthPrice[monthYear] = 0;
          }
          ordersByMonthPrice[monthYear] += orderTotal;

          if (createdAt >= currentWeekStart) {
            if (!ordersByWeekPrice[dayOfWeek]) {
              ordersByWeekPrice[dayOfWeek] = 0;
            }
            ordersByWeekPrice[dayOfWeek] += orderTotal;
          }
        });

        // Now you can use the ordersByMonthPrice and ordersByWeekPrice variables as needed
        console.log("Orders by month price:", ordersByMonthPrice);
        console.log("Orders by week price:", ordersByWeekPrice);

        setTotalOrdersByMonth(ordersByMonthPrice);
        setTotalOrdersByWeek(ordersByWeekPrice);
        setProcessingOrders(processingOrdersData.length);
        setPendingOrders(pendingOrdersData.length);
        setCancelOrders(cancelOrdersData.length);
        setDeliveredOrders(deliveredOrdersData.length);
        setTotalOrders(totalPrice);
        setTotalPendingOrders(totalPendingPrice);
        setTotalCancelOrders(totalCancelPrice);
      } else {
        console.error("No data found");
      }
    } catch (error) {
      console.error("Error fetching order data:", error);
    }
  };




  const navigate = useNavigate();



  useEffect(() => {

    getproducttotal();
    getordertotal();
    getuserlisttotal();
    getOrderdata();
    getReviewListtotal();
    getReviewtotal();
    getcategorytotal();
    getcoupontotal();
    getusertotal();
    getsubcategorytotal();
    const adminInfo = localStorage.getItem("ADMIN-INFO");
    if (adminInfo === null) {
      navigate("/login");
    }
  }, [navigate]);

  useEffect(() => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const allMonths = Array.from({ length: 12 }, (_, i) => {
      const date = new Date(currentYear, i);
      return date.toLocaleString('en-US', { month: 'long' }); // Only month name
    });

    const newLabels = allMonths;
    const newData = allMonths.map((month, index) => {
      const key = `${currentYear}-${(index + 1).toString().padStart(2, '0')}`;
      return totalOrdersByMonth[key] || 0; // Default to 0 if no data
    });

    setChartData({
      labels: newLabels,
      datasets: [
        {
          label: `Total Earning - ${currentYear}`, 
          data: newData,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    });
  }, [totalOrdersByMonth]);


  const chartData = {
    labels: ['Pending', 'Processing', 'Canceled', 'Delivered'],
    datasets: [
      {
        label: 'Order Status',
        data: [pendingOrders, processingOrders, cancelOrders, deliveredOrders],
        backgroundColor: [
          'rgba(255, 206, 86, 0.2)',  // Pending
          'rgba(75, 192, 192, 0.2)',  // Processing
          'rgba(255, 99, 132, 0.2)',  // Canceled
          'rgba(54, 162, 235, 0.2)'   // Delivered (new color)
        ],
        borderColor: [
          'rgba(255, 206, 86, 1)',    // Pending
          'rgba(75, 192, 192, 1)',    // Processing
          'rgba(255, 99, 132, 1)',    // Canceled
          'rgba(54, 162, 235, 1)'     // Delivered (new color)
        ],
        borderWidth: 1,
      },
    ],
  };


  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat('en-US').format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };




  const allMonths = Array.from({ length: 12 }, (_, i) => {
    const date = new Date(2024, i);
    return date.toLocaleString('en-US', { month: 'long', year: 'numeric' }); // Format as "January 2024"
  });

  // Extract the months from the data keys
  const monthKeys = Object.keys(ordermonthdata).map(monthYear => {
    const [year, month] = monthYear.split('-');
    const date = new Date(year, month - 1); // Month is 0-indexed in Date object
    return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
  });

  // Ensure all months are displayed
  const filteredMonthLabels = allMonths;
  const filteredCounts = filteredMonthLabels.map(label => {
    const [month, year] = label.split(' ');
    const monthIndex = new Date(Date.parse(month + " 1, 2024")).getMonth() + 1; // Get month index
    const key = `${year}-${monthIndex.toString().padStart(2, '0')}`;
    return ordermonthdata[key] || 0; // Default to 0 if no data
  });
  const filteredPrices = filteredMonthLabels.map(label => {
    const [month, year] = label.split(' ');
    const monthIndex = new Date(Date.parse(month + " 1, 2024")).getMonth() + 1; // Get month index
    const key = `${year}-${monthIndex.toString().padStart(2, '0')}`;
    return ordermonthPricedata[key] || 0; // Default to 0 if no data
  });


  const [chartData1, setChartData] = useState({
    labels: filteredMonthLabels,
    datasets: [
      {
        label: 'Total Earning',
        data: filteredPrices,
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1,
      },
    ],
  });

  // const handleOptionChange = (option) => {
  //   setSelectedOption(option);// Set the active option

  //   let newLabels = [];
  //   let newData = [];
  //   const currentDate = new Date();
  //   const currentMonth = currentDate.getMonth();
  //   const currentYear = currentDate.getFullYear();
  //   const currentWeekStart = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));

  //   switch (option) {
  //     case 'month':
  //       // Display data for the current month
  //       newLabels = [new Date().toLocaleString('en-US', { month: 'long', year: 'numeric' })];
  //       newData = [totalOrdersByMonth[`${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}`] || 0];
  //       break;
  //     case 'year':
  //       // Display data for the current year
  //       const allMonths = Array.from({ length: 12 }, (_, i) => {
  //         const date = new Date(currentYear, i);
  //         return date.toLocaleString('en-US', { month: 'long', year: 'numeric' });
  //       });

  //       newLabels = allMonths;
  //       newData = allMonths.map(label => {
  //         const [month, year] = label.split(' ');
  //         const monthIndex = new Date(Date.parse(month + " 1, " + year)).getMonth() + 1; // Get month index
  //         const key = `${year}-${monthIndex.toString().padStart(2, '0')}`;
  //         return totalOrdersByMonth[key] || 0; // Default to 0 if no data
  //       });
  //       break;
  //     case 'week':
  //       // Display data for the current week
  //       newLabels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  //       newData = newLabels.map((_, index) => totalOrdersByWeek[index] || 0);
  //       break;
  //     default:
  //       break;
  //   }

  //   setChartData({
  //     labels: newLabels,
  //     datasets: [
  //       {
  //         label: 'Total Earning',
  //         data: newData,
  //         backgroundColor: 'rgba(255, 99, 132, 0.2)',
  //         borderColor: 'rgba(255, 99, 132, 1)',
  //         borderWidth: 1,
  //       },
  //     ],
  //   });
  // };
  const handleOptionChange = (option) => {
    setSelectedOption(option); // Set the active option
  
    let newLabels = [];
    let newData = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();
    const currentWeekStart = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay()));
  
    let chartTitle = 'Total Earning'; // Default title
  
    switch (option) {
      case 'month':
        // Display data for the current month
        newLabels = [new Date().toLocaleString('en-US', { month: 'long' })]; // Only month name
        newData = [
          totalOrdersByMonth[`${currentYear}-${(currentMonth + 1).toString().padStart(2, '0')}`] || 0
        ];
        chartTitle = `Total Earning - ${currentYear}`; // Set year in the chart title
        break;
      case 'year':
        // Display data for the current year
        const allMonths = Array.from({ length: 12 }, (_, i) => {
          const date = new Date(currentYear, i);
          return date.toLocaleString('en-US', { month: 'long' });
        });
  
        newLabels = allMonths; // Only month names
        newData = allMonths.map((month, index) => {
          const key = `${currentYear}-${(index + 1).toString().padStart(2, '0')}`;
          return totalOrdersByMonth[key] || 0; // Default to 0 if no data
        });
        chartTitle = `Total Earning - ${currentYear}`; // Year shown in the chart title
        break;
      case 'week':
        // Display data for the current week
        newLabels = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        newData = newLabels.map((_, index) => totalOrdersByWeek[index] || 0);
        chartTitle = `Total Earning - ${new Date().toLocaleString('en-US', { month: 'long' })} ${currentYear}`; // Set current month and year in title
        break;
      default:
        break;
    }
  
    setChartData({
      labels: newLabels,
      datasets: [
        {
          label: chartTitle, // Use dynamic title
          data: newData,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
        },
      ],
    });
  };
  
  const orderChartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  const buttonStyles = (option) => ({
     backgroundColor: selectedOption === option ? 'rgba(0, 123, 255, 0.5)' : 'rgba(255, 255, 255, 1)',
     color: selectedOption === option ? 'white' : 'black',
     border: '1px solid rgba(0, 123, 255, 1)',
      margin: '0 5px'
   });
  // const buttonStyles = (option) => ({
  //   backgroundColor: selectedOption === option ? '#007bff' : '#ffffff', // Change color when selected
  //   color: selectedOption === option ? '#ffffff' : '#007bff',
  //   borderColor: '#007bff',
  //   margin: '0 5px'
  // });
  const chartUserOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        fullWidth: true, // Ensures the legend takes up the full width
        labels: {
          font: {
            size: 10, // Reduce the font size further
          },
          padding: 15, // Reduce padding between labels
          boxWidth: 15, // Reduce the box width to make labels more compact
        },
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed !== null) {
              label += new Intl.NumberFormat('en-US').format(context.parsed);
            }
            return label;
          },
        },
      },
    },
    layout: {
      padding: {
        top: 10,
        bottom: 10,
      },
    },
  };

  const chartuserData = {
    labels: [
      'Login User',
      'Guest User',
      'Total Product',
      'Total Category',
      'Total Sub Category',
      'Total Coupon'
    ],
    datasets: [
      {
        label: 'Total Business Overview',
        data: [
          totaluserlist,
          totalguestuser,
          totalproduct,
          totalcategory,
          totalsub,
          totalcoupon
        ],
        backgroundColor: [
          'rgba(139, 0, 0, 0.8)',      // Dark Red for Login User
          'rgba(0, 0, 139, 0.8)',      // Dark Blue for Guest User
          'rgba(128, 128, 0, 0.8)',    // Olive for Total Product
          'rgba(47, 79, 79, 0.8)',     // Dark Slate Gray for Total Category
          'rgba(75, 0, 130, 0.8)',     // Indigo for Total Sub Category
          'rgba(139, 69, 19, 0.8)'     // Saddle Brown for Total Coupon
        ],
        borderColor: [
          'rgba(139, 0, 0, 1)',        // Dark Red for Login User
          'rgba(0, 0, 139, 1)',        // Dark Blue for Guest User
          'rgba(128, 128, 0, 1)',      // Olive for Total Product
          'rgba(47, 79, 79, 1)',       // Dark Slate Gray for Total Category
          'rgba(75, 0, 130, 1)',       // Indigo for Total Sub Category
          'rgba(139, 69, 19, 1)'       // Saddle Brown for Total Coupon
        ],
        borderWidth: 1,
      },
    ],
  };


  return (
    <div>
      <body id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>

          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header></Header>

              <div className="container-fluid">
                <div className="d-sm-flex align-items-center justify-content-between mb-4">
                  <h1 className="h3 mb-0 text-gray-800 welcome_heading">
                    Welcome To Dashboard
                  </h1>
                </div>

                <div className="row">
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body all_heading_name">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/categoryList"
                                className="collapse-item "
                                style={{ fontSize: "14px" }}
                              >
                                Categories : {totalcategory}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-calendar fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body all_heading_name">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/subcategoryList"
                                className="nav-link"
                                style={{ fontSize: "14px" }}
                              >
                                Sub Categories : {totalsub}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-success shadow h-100 py-2">
                      <div className="card-body all_heading_name">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-success text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/productListPage"
                                className="collapse-item"
                                style={{ fontSize: "14px" }}
                              >
                                Products : {totalproduct}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-primary shadow h-100 py-2">
                      <div className="card-body all_heading_name">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-primary text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/orderList"
                                className="collapse-item"
                                style={{ fontSize: "14px" }}
                              >
                                Orders : {totalorder}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-calendar fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body all_heading_name">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/coupenListPage"
                                className="nav-link"
                                style={{ fontSize: "14px" }}
                              >
                                Coupons : {totalcoupon}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body all_heading_name">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/userList"
                                className="nav-link"
                                style={{ fontSize: "14px" }}
                              >
                                Users : {totaluser}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-3 col-md-6 mb-4">
                    <div className="card border-left-warning shadow h-100 py-2">
                      <div className="card-body all_heading_name">
                        <div className="row no-gutters align-items-center">
                          <div className="col mr-2">
                            <div className="text-xs font-weight-bold text-warning text-uppercase mb-1 luar_dashboard_font_heading">
                              <NavLink
                                to="/reviewList"
                                className="nav-link"
                                style={{ fontSize: "14px" }}
                              >
                                Reviews : {totalReview}
                              </NavLink>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                          </div>
                          <div className="col-auto">
                            <i className="fas fa-comments fa-2x text-gray-300"></i>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>


                <div className="row">
                  <div className="col-xl-12 col-lg-12">
                    <div className="card shadow mb-12">
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold laur_dash_earning_static">Earning Statistics</h6>
                        {/* <div className="dasboard_this_year_button"> */}
                        <div className="">
                          <button
                            className="btn btn-primary"
                            style={buttonStyles('year')}
                            onClick={() => handleOptionChange('year')}
                          >
                            This Year
                          </button>
                          <button
                            className="btn btn-primary"
                            style={buttonStyles('month')}
                            onClick={() => handleOptionChange('month')}
                          >
                            This Month
                          </button>

                          <button
                            className="btn btn-primary"
                            style={buttonStyles('week')}
                            onClick={() => handleOptionChange('week')}
                          >
                            This Week
                          </button>
                        </div>
                      </div>
                      <div className="card-body" style={{ height: '500px' }}>
                        <div className="chart-container" style={{ height: '100%' }}>
                          <Bar data={chartData1} options={orderChartOptions} />

                        </div>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="row">
                  <div className="col-xl-6 col-lg-4">
                    <div className="card shadow mb-12" style={{ height: '100%' }}>
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold laur_dash_earning_static">Order Status Graph</h6>
                      </div>
                      <div className="card-body" style={{ height: '400px' }}> {/* Set a consistent height */}
                        <div className="chart-container" style={{ height: '100%' }}>
                          <Bar data={chartData} options={chartOptions} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-8">
                    <div className="card shadow mb-12" >
                      <div className="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 className="m-0 font-weight-bold laur_dash_earning_static">Total Business Overview</h6>
                      </div>
                      <div className="card-body" style={{ height: '400px' }}> {/* Set a consistent height */}
                        <div className="chart-container" style={{ height: '100%' }}>
                          <Doughnut data={chartuserData} options={chartUserOptions} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



              </div>
            </div>

            <Footer></Footer>

          </div>
        </div>

      </body>
    </div>
  );
}

export default Dashboard;
