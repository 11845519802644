import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from '../Controller/API';
/*********************************************************
 *  This function is use to getOrderDatafetch api
 *********************************************************/
export const getOrderDatafetch = async (options , authToken)=>{
    try{
        const {page,product_name}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'order/list-order',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
export const getlistOrderDatafetch = async (options , authToken)=>{
  try{
      const {page,product_name}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'order/orderList',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
export const deleteOrderData = async (options , authToken)=>{
  try{
      const {id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'order/order-delete',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await deleteRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
export const getOrderDataExcel = async (options , authToken)=>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'order/get-order-excel',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function

export const getordertotalDatafetch = async (options , authToken)=>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'order/total-order',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
export const getOrderDetailDatafetch = async (options , authToken)=>{
  try{
      const {order_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'order/order-detail',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 
export const getBulkOrderDatafetch = async (options , authToken)=>{
  try{
      const {page,product_name}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'order/bulk-order-list',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
/*********************************************************
 *  This function is use to addMainformData api
 *********************************************************/
 export const addMainformData = async (options, authToken) => {
    try {
      const { title, description, image_1,image_2,image_3 } = options;
  
      // Create a FormData object
      const formData = new FormData();
      formData.append('title', title);
      formData.append('description', description);
      formData.append('image_3', image_3);
      formData.append('image_1', image_1);
      formData.append('image_2', image_2);
  
      
      const posrData = {
        url: API_ADMIN_URL + 'main/add-main',
        postData: formData,
        headers: {
          authToken: authToken,
          'Content-Type': 'multipart/form-data',
        },
      };
  
  
      const res = await postRequest(posrData);
  
      if (res) {
        return res;
      } else {
        return { status: false, message: res?.response?.data?.statusMessage };
      }
    } catch (error) {
      return { status: false, message: "Under Maintenance, Please try after some time." };
    }
  };
   // End of Function
   /*********************************************************
 *  This function is use to updateMainformData api
 *********************************************************/
   export const orderStatus = async (options, authToken) => {
    try {
      const { id,status } = options;
      const postData = {
        url: API_ADMIN_URL + 'order/status-order',
        postData: options,
        headers: {
          authToken: authToken,
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  
 // End of Function

 export const orderCancel = async (options, authToken) => {
    try {
      const { id,status } = options;
      const postData = {
        url: API_ADMIN_URL + 'order/cancel-order',
        postData: options,
        headers: {
          authToken: authToken,
        },
      };
      const res = await postRequest(postData);
  
      if (res && res.status === 200) {
        return res.data; // Assuming your response has a 'data' property
      } else {
        return { status: false, message: res?.data?.statusMessage || 'Request failed.' };
      }
    } catch (error) {
      console.error('Error updating banner:', error);
      return { status: false, message: 'Under Maintenance, Please try after some time.' };
    }
  };
  export const shippingOrderCancelData = async (options , authToken)=>{
    try{
        const { ids,auth}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'order/api/orders/cancel-order',
                postData : options,
               
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
  }; 
 // End of Function
 export const getOrderShippingcreate = async (options , authToken)=>{
  try{
      const {shipment_order_id,shipment_id,order_id,order_detail_id}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'order/shipping-order-create',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function
export const getOrderShippingstatus = async (options , authToken)=>{
  try{
      const {shipment_order_id,status}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'order/shipping-order-status',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 

export const getOrderShipping = async (options , authToken)=>{
  try{
      const {order_id,order_detail_id,user_id,product_id,order_date,pickup_location,channel_id,comment,shipping_charges,breadth,height,weight,length}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'order/shipping-order',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 

export const shippingOrderData = async (options , authToken)=>{
  try{
      const { order_id,
        order_date,
        pickup_location,
        channel_id,
        billing_customer_name,
        billing_last_name,
        billing_address,
        billing_city,
        billing_pincode,
        billing_state,
        billing_country,
        billing_email,
        billing_phone,
        shipping_is_billing,
        order_items,
        payment_method,
        shipping_charges,
        giftwrap_charges,
        transaction_charges,
        total_discount,
        sub_total,
        height,
        breadth,
        weight,
        length,
        auth}= options;
        console.log(options);
          const posrData ={ 
              url : API_ADMIN_URL+'order/api/orders/shipping-order',
              postData : options,
             
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; 