import React, { useState, useEffect } from "react";
import SideBar from "../SideBar/SideBar";
import Header from "../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { FaEdit } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import CategoryDelete from "./CollectionDelete";
import CategoryChange from "./AddCollectionstatuspopup";
import { getCollectionDatafetch,getCategoryDataExcel } from "../Controller/Collection";
import "./Collection.css";
import { BsThreeDots } from "react-icons/bs";
import Excel from 'exceljs';

function ListCategoryData() {
  // Delete Popup
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [itemIdToChange, setItemIdTochange] = useState(null);
  const [itemChange, setstatuschange] = useState(null);
  const [popUpOpenchange, setPopUpOpenchange] = useState(false);
  const [categoryList, setCategoryData] = useState([]);
  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
    setItemIdToDelete(itemId);
    getcollectiondata();
  };

  const togglePopUpchange = (itemId, status) => {
    setPopUpOpenchange(!popUpOpenchange);
    setItemIdTochange(itemId);
    setstatuschange(status === "A" ? "I" : "A");
    getcollectiondata();
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    getcollectiondata(currentPage, value);
  };

  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getcollectiondata(page);
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getcollectiondata = async (page, name) => {
    const options = {
      page: page,
      name: name, // Include additional search options
    };
    try {
      const result = await getCollectionDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.collection;
        const totalcount = result.data.response.result.totalcount;
        setCategoryData(responseData);
        setTotalItems(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching profile data:", error);
    }
  };
  const downloadExcel = async () => {
    try {
        const result = await getCategoryDataExcel({}, adminInformation.token);
        if (result.status === true || result.status === 200) {
            const categories = result.data.response.result; // Extract categories from result.data
            if (categories && categories.length > 0) {
                const workbook = new Excel.Workbook();
                const worksheet = workbook.addWorksheet('collections');
                
                // Define column headers
                worksheet.columns = [
                    { header: 'ID', key: '_id', width: 15 },
                    { header: 'Name', key: 'name', width: 30 },
                    // Add more columns as needed
                ];

                // Add data rows
                categories.forEach((category, index) => {
                    worksheet.addRow({
                        _id: category._id, // Use _id consistently here
                        name: category.name,
                        // Add more properties as needed
                    });
                });

                // Write workbook to blob
                const buffer = await workbook.xlsx.writeBuffer();

                // Create a blob from buffer
                const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

                // Create a temporary anchor element
                const anchor = document.createElement('a');
                anchor.href = URL.createObjectURL(blob);
                anchor.download = 'collections.xlsx';

                // Programmatically trigger a click event on the anchor element
                anchor.click();
            } else {
                console.error('Error downloading Excel data: No data available');
            }
        } else {
            console.error('Error downloading Excel data:', result.message);
        }
    } catch (error) {
        console.error('Error downloading Excel data:', error);
    }
};


  const handleRowClick = (itemId) => {
    navigate(`/editCollectionData/${itemId}`);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getcollectiondata(currentPage, searchValue);
  }, [currentPage, searchValue]);

  const dropdownMenu = (itemId,status) => (
    <Menu>
      <Menu.Item key="2" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUpchange(itemId, status)}>
        <FaEdit />
        <span className="show_span_edit">
          {status === "A" ? "Inactive" : "Active"}
        </span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Collection List</h1>
                <p className="mb-4"></p>
                {/* <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search text"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div> */}
                <div className="card shadow mb-4">
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                     
                    >
                      Collection List Table
                    </h6>
                    <div className="my_add_event_form_field"  style={{ float: "right" }}>
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker" style={{ marginTop: "-30px" }}>
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search text"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                        />
                      </div>
                    </Form.Group>
                      <Link to="/createCollection">
                        <button
                          type="button"
                          className="btn btn-primary"
                          
                        >
                          Add Collection Data
                        </button>
                      </Link>
                      <button onClick={downloadExcel} className="btn btn-success ml-2">
                        Download Excel
                      </button>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {/* <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheckTitle"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheckTitle"
                                >
                                  S No
                                </label>
                              </div> */}
                              S.No
                            </th>
                            <th scope="col">Collection Name</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {categoryList.length > 0 ? (
                            categoryList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customCheckTitle${item.id}`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customCheckTitle${item.id}`}
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </label>
                                  </div> */}
                                  {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>{item.name}</td>
                                <td
                                  style={{
                                    color:
                                      item.status === "A" ? "green" : "red",
                                  }}
                                >
                                  {item.status === "A" ? "Active" : "Inactive"}
                                </td>
                                <td>
                                  <Dropdown
                                    overlay={() => dropdownMenu(item._id,item.status)}
                                    placement="bottomLeft"
                                    arrow
                                  >
                                    {/* <FaRegCommentDots /> */}
                                    <BsThreeDots />
                                  </Dropdown>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="all_pagination">
                      <p>Show {((currentPage - 1) * 10) + 1} to {Math.min(currentPage * 10, totalItems)} of {totalItems}</p>
                      
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CategoryDelete
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      ></CategoryDelete>
       <CategoryChange
        popUpOpenchange={popUpOpenchange}
        togglePopUpchange={togglePopUpchange}
        itemIdToChange={itemIdToChange}
        status={itemChange} // Assuming status is available in your data
      />
    </div>
  );
}
export default ListCategoryData;
