import { RiEyeCloseFill } from 'react-icons/ri';
import  { API_ADMIN_URL }  from '../Config/constant';
import {getRequest, postRequest,deleteRequest} from './API';
/*********************************************************
 *  This function is use to getCoupenDatafetch api
 *********************************************************/
export const getCoupenDatafetch = async (options , authToken)=>{
    try{
        const {page,coupon_code}= options;
            const posrData ={ 
                url : API_ADMIN_URL+'review/list-review',
                postData : options,
                headers: {
                  authToken: authToken
                }
             }
            const res = await postRequest(posrData);
            if(res.status === true || res.status === 200){
                return res;
            } else{
                return {status : false, message:res?.response?.data?.statusMessage}
            }
    }catch(error){
        return {status : false, message:"Under Maintanance, Please try after some time."}
    }
}; //End of Function
export const getReviewDataListfetch = async (options , authToken)=>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'review/list-review-total',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
}; //End of Function


export const statusCategoryData = async (options , authToken) =>{
  try{
      const {id,status}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'review/status',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
export const getReviewDatafetch = async (options , authToken) =>{
  try{
      const {}= options;
          const posrData ={ 
              url : API_ADMIN_URL+'review/total-review',
              postData : options,
              headers: {
                authToken: authToken
              }
           }
          const res = await postRequest(posrData);
          if(res.status === true || res.status === 200){
              return res;
          } else{
              return {status : false, message:res?.response?.data?.statusMessage}
          }
  }catch(error){
      return {status : false, message:"Under Maintanance, Please try after some time."}
  }
};
/*********************************************************
 *  This function is use to deleteCoupenData api
 *********************************************************/
export const deleteCoupenData = async (options ,authToken) => {
  try {
    const { id } = options;
    const postData = {
      id: id,
    };
    const postOptions = {
      url: API_ADMIN_URL + 'review/delete-review',
      postData: postData,
      headers: {
        authToken: authToken
      }
    };
    const res = await deleteRequest(postOptions);
    if (res) {
      return res;
    } else {
      return { status: false, message: res?.response?.data?.statusMessage };
    }
  } catch (error) {
    return { status: false, message: "Under Maintenance, Please try after some time." };
  }
};
