import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Pagination from "@mui/material/Pagination";
import { MdDelete } from "react-icons/md";
import { FiEdit, FiTrash } from 'react-icons/fi'; // Import edit and delete icons
import Switch from "react-switch"; // Import react-switch

import BannerataDelete from "./BannerDelete";
import CategoryChange from "./Bannerstatus";
import { getBannerDatafetch } from "../../Controller/Banner";
import {getCategoryDatafetch} from "../../Controller/Product";
import "./Banner.css";

function ListBannerData() {
  // Delete Popup
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [itemIdToChange, setItemIdTochange] = useState(null);
  const [itemChange, setstatuschange] = useState(null);
  const [popUpOpenchange, setPopUpOpenchange] = useState(false);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [categories, setCategoryData] = useState([]);
  const [bannerlList, setBannerData] = useState([]);
  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
    setItemIdToDelete(itemId);
    getbannerdata();
  };
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getbannerdata = async (page, page_name) => {
    const options = {
      page: page,
      page_name: page_name,
    };

    try {
      const result = await getBannerDatafetch(options, adminInformation.token);
      console.log("API Result:", result);

      if (result.data && result.data.response) {
        const responseData = result.data.response.result.banner;
        const totalcount = result.data.response.result.totalcount;

        setBannerData(responseData);
        setTotalItems(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleRowClick = (itemId) => {
    // history.push(`/createEventEditPage/${itemId}`);
    navigate(`/editBannerData/${itemId}`);
  };
  const handlePageChange = (event, page) => {
    console.log("Page changed to", page);
    setCurrentPage(page);
    getbannerdata(page);
  };
  const handleSearch = (value) => {
    console.log("Search Value:", value);
    setSearchValue(value);
    getbannerdata(currentPage, value);
  };
  const togglePopUpchange = (itemId, checked) => {
    setPopUpOpenchange(!popUpOpenchange);
    setItemIdTochange(itemId);
    setstatuschange(checked ? "A" : "I"); // Use checked to determine the status
    getbannerdata();
  };
  const handleViewDetail = (item) => {
    console.log(item);
    navigate('/editBannerData', { state: { selectedItem: item } });
  };

  function formatType(type) {
    // Split the string by underscores
    let words = type.split('_');
  
    // Capitalize the first letter of each word
    words = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));
  
    // Join the words back together
    return words.join(' ');
  }
  const fetchCategories = async () => {
    const options = {};
    try {
      const result = await getCategoryDatafetch(
        options,
        adminInformation.token
      );
      if (result.data && result.data.response) {
        const responseData = result.data.response.result;
        setCategoryData(responseData);
      } else {
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getbannerdata(currentPage, searchValue);
    fetchCategories();
  }, [currentPage, searchValue]);

  const dropdownMenu = (itemId,status,item) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleViewDetail(item)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => togglePopUpchange(itemId, status)}>
        <FaEdit />
        <span className="show_span_edit">
          {status === "A" ? "Inactive" : "Active"}
        </span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* Main Content */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800">Banners List</h1>
                <p className="mb-4"></p>
                {/* <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search page name"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div> */}
                <div className="card shadow mb-4"  style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                      
                    >
                      Banners List Table
                    </h6>
                    <div className="my_add_event_form_field " style={{ float: "right" }}>
                    {/* <Form.Group controlId="formDate">
                      <div className="my_date_picker" style={{ marginTop: "-30px" }}>
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search text"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                          className="input_type_search_common"
                        />
                      </div>
                    </Form.Group> */}
                      <Link to="/createBanner">
                        <button
                          type="button"
                          className="btn btn-primary"
                          style={{ 
                            backgroundColor: "#e7aeb6",
                            color: "#fff",
                            borderColor: "#e7aeb6"
                          }}
                        >
                          Add Banner Data
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th>
                              {/* <div className="custom-control custom-checkbox">
                                <input
                                  type="checkbox"
                                  className="custom-control-input"
                                  id="customCheckTitle"
                                />
                                <label
                                  className="custom-control-label"
                                  htmlFor="customCheckTitle"
                                >
                                  S No
                                </label>
                              </div> */}
                              S.No
                            </th>
                            <th scope="col">Page Name</th>
                            <th scope="col">CategoryChange</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {bannerlList.length > 0 ? (
                            bannerlList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customCheckTitle${item.id}`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customCheckTitle${item.id}`}
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </label>
                                  </div> */}
                                  {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>{formatType(item.page_name)}</td>
                                <td>
                                  {categories.find(
                                    (category) =>
                                      category._id === item.category_id
                                  )?.name || ""}
                                </td>
                                <td>
                                  <Switch className="all_switch_button"
                                  checked={item.status === "A"}
                                  onChange={(checked) => togglePopUpchange(item._id, checked)}
                                  offColor="#f44336" 
                                  onColor="#4caf50" 
                                  offHandleColor="#fff" 
                                  onHandleColor="#fff" 
                                />
                                </td>
                                <td>
                                <FiEdit
                                    style={{ cursor: 'pointer', color: '#4caf50' }} 
                                    onClick={() => handleViewDetail(item)}
                                  />
                                    <FiTrash
                                      style={{ cursor: 'pointer', color: '#f44336' }} // Styling for the Delete icon
                                      onClick={() => togglePopUp(item._id)} // Function to handle the delete action
                                    />
                                
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="all_pagination">
                      <p>Show {((currentPage - 1) * 10) + 1} to {Math.min(currentPage * 10, totalItems)} of {totalItems}</p>
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BannerataDelete
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      ></BannerataDelete>
      <CategoryChange
        popUpOpenchange={popUpOpenchange}
        togglePopUpchange={togglePopUpchange}
        itemIdToChange={itemIdToChange}
        status={itemChange} // Assuming status is available in your data
      />
    </div>
  );
}
export default ListBannerData;
