import React, { useState, useEffect } from "react";
import SideBar from "../../SideBar/SideBar";
import Header from "../../HeaderDashBoard/Header";
import { Form } from "react-bootstrap";
import { DatePicker, Input, Select, Dropdown, Menu } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FaRegCommentDots } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { FaEdit } from "react-icons/fa";
import Switch from "react-switch"; // Import react-switch
import { FiEdit, FiTrash } from 'react-icons/fi'; // Import edit and delete icons

import "../../AllCssFile.css";
import { MdDelete } from "react-icons/md";
import ProductDeletePopUp from "./ProductDeletePopUp";
import Pagination from "@mui/material/Pagination";
import { getProductNamreDatafetch,getproductnameDataExcel} from "../../Controller/Product";
import Excel from "exceljs";
import ProductOutPopUp from "./ProductStockPopUp";
function ListProductName() {
  const adminInformationString = localStorage.getItem("ADMIN-INFO");
  const adminInformation = JSON.parse(adminInformationString);
  const navigate = useNavigate();
  const [itemIdToDelete, setItemIdToDelete] = useState(null);
  const [itemIdToOut, setItemIdToOut] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); // add this line
  const [totalItems, setTotalItems] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [itemIdToStatus, setItemIdStatus] = useState(null);
  const [popUpOpen, setPopUpOpen] = useState(false);
  const [popUpOpenstock, setPopUpOpenstock] = useState(false);
  const [productList, setProductData] = useState([]);
  const [popUpOpenchange, setPopUpOpenchange] = useState(false);
  const [itemIdToChange, setItemIdTochange] = useState(null);
  const [itemChange, setstatuschange] = useState(null);
  const togglePopUp = (itemId) => {
    setPopUpOpen(!popUpOpen);
    setItemIdToDelete(itemId);
    getProductdata();
  };
  
  /*************************************************************
   * Function Name  : getsocialdata
   * Purposs        : This function is used get data
   * Created Date   : 09-01-2024
   *************************************************************/
  const getProductdata = async (page, product_name) => {
    const options = {
      page: page,
      product_name: product_name,
    };

    try {
      const result = await getProductNamreDatafetch(options, adminInformation.token);
      if (result.data && result.data.response) {
        const responseData = result.data.response.result.productName;
        const totalcount = result.data.response.result.totalcount;

        setProductData(responseData);
        setTotalItems(totalcount);
      } else {
        localStorage.removeItem('TOKEN');
        localStorage.removeItem('ADMIN-INFO');
        navigate("/login");
        console.error("Unexpected response format:", result.data);
      }
    } catch (error) {
      console.error("Error fetching banner data:", error);
    }
  };
  const handleRowClick = (itemId) => {
    navigate(`/editProductNameData/${itemId}`);
  };
  const handlePageChange = (event, page) => {
    setCurrentPage(page);
    getProductdata(page);
  };
  const handleSearch = (value) => {
    setSearchValue(value);
    getProductdata(currentPage, value);
  };
  const capitalizeAfterSpace = (str) => {
    if (str) {
      return str.replace(/(?:^|\s)\S/g, (match) => match.toUpperCase());
    }
    return "";
  };
  const togglePopUpchange = (itemId, checked) => {
    setPopUpOpenchange(!popUpOpenchange);
    setItemIdTochange(itemId);
    setstatuschange(checked ? "A" : "I"); // Use checked to determine the status
    getProductdata();
  };
  const downloadExcel = async () => {
    try {
      const result = await getproductnameDataExcel({}, adminInformation.token);
      console.log("result",result);
      if (result.status === true || result.status === 200) {
        const productnames = result.data.response.editData; // Extract categories from result.data
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet("Product_names");

        // Define column headers
        worksheet.columns = [
          { header: "Product Name ID", key: "_id", width: 15 },
          { header: "Product Name", key: "product_name", width: 30 },
          { header: "Product Name Slug", key: "product_slug", width: 20 },
          // Add more columns as needed
        ];

        // Add data rows
        productnames.forEach((productname, index) => {
          worksheet.addRow({
            _id: productname._id, // Use _id consistently here
            product_name: productname.product_name,
            product_slug: productname.product_slug,
            // Add more properties as needed
          });
        });

        // Write workbook to blob
        const buffer = await workbook.xlsx.writeBuffer();

        // Create a blob from buffer
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });

        // Create a temporary anchor element
        const anchor = document.createElement("a");
        anchor.href = URL.createObjectURL(blob);
        anchor.download = "productnames.xlsx";

        // Programmatically trigger a click event on the anchor element
        anchor.click();
      } else {
        console.error("Error downloading Excel data:", result.message);
      }
    } catch (error) {
      console.error("Error downloading Excel data:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getProductdata(currentPage, searchValue);
  }, [currentPage, searchValue]);
  function formatDate(dateString) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
    };

    return new Date(dateString).toLocaleDateString(undefined, options);
  }
  const dropdownMenu = (itemId, status) => (
    <Menu>
      <Menu.Item key="1" onClick={() => handleRowClick(itemId)}>
        <FaEdit />
        <span className="show_span_edit">Edit</span>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => togglePopUpchange(itemId, status)}>
        <FaEdit />
        <span className="show_span_edit">
          {status === "A" ? "Inactive" : "Active"}
        </span>
      </Menu.Item>
      <Menu.Item key="3" onClick={() => togglePopUp(itemId)}>
        <MdDelete />
        <span className="show_span_edit">Delete</span>
      </Menu.Item>
    </Menu>
  );

 

  const { Search } = Input;
  return (
    <div>
      <div id="page-top">
        <div id="wrapper">
          <SideBar></SideBar>
          <div id="content-wrapper" className="d-flex flex-column">
            {/* <!-- Main Content --> */}
            <div id="content">
              <Header />
              <div className="container-fluid">
                <h1 className="h3 mb-2 text-gray-800"> Product Names List </h1>
                {/* <div className="table_head_main_class">
                  <div className="my_antd_date_picker1">
                    <Form.Group controlId="formDate">
                      <div className="my_date_picker">
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search title"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                        />
                      </div>
                    </Form.Group>
                  </div>
                </div> */}
                <div className="card shadow mb-4" style={{ backgroundColor: "#fff8f9" }}>
                  <div className="card-header py-3 dataBase_table_Add_Data">
                    <h6
                      className="m-0 font-weight-bold"
                     
                    >
                      Product Names List Table
                    </h6>
                    <div
                      className="my_add_event_form_field "
                      style={{ float: "right" }}
                    >
                       {/* <Form.Group controlId="formDate">
                      <div className="my_date_picker" style={{ marginTop: "-30px" }}>
                        <Form.Label>Search</Form.Label>
                        <Search
                          placeholder="input search text"
                          onChange={(e) => handleSearch(e.target.value)}
                          value={searchValue}
                          className="input_type_search_common" 
                        />
                      </div>
                    </Form.Group> */}
                    <Link to="/createProductName">
                      <button
                        type="button"
                        className="btn btn-primary"  style={{ 
                          backgroundColor: "#e7aeb6",
                          color: "#fff",
                          borderColor: "#e7aeb6"
                        }}
                       
                      >
                        Add Product Name 
                      </button>
                    </Link>
                    <button
                          onClick={downloadExcel}
                          className="btn btn-success ml-2"
                        >
                          Download Excel
                        </button>
                    {/* <Link to="/productBulkData">
                      <button
                        type="button"
                        className="btn btn-primary" 
                       
                      >
                        Add Bulk Product
                      </button>
                    </Link> */}
                  </div>
                  </div>

                  <div className="card-body">
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Product Name</th>
                            <th scope="col">Created Date</th>
                            <th scope="col">Last Updated Date</th>
                            <th scope="col">Status</th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {productList.length > 0 ? (
                            productList.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {/* <div className="custom-control custom-checkbox">
                                    <input
                                      type="checkbox"
                                      className="custom-control-input"
                                      id={`customCheckTitle${item.id}`}
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={`customCheckTitle${item.id}`}
                                    >
                                      {(currentPage - 1) * 10 + index + 1}
                                    </label>
                                  </div> */}
                                  {(currentPage - 1) * 10 + index + 1}
                                </td>
                                <td>
                                  {capitalizeAfterSpace(item.product_name)}
                                </td>
                               
                                <td>{formatDate(item.createdAt)}</td>
                                <td>{formatDate(item.updatedAt)}</td>
                                <td>
                                  <Switch className="all_switch_button"
                                  checked={item.status === "A"}
                                  onChange={(checked) => togglePopUpchange(item._id, checked)}
                                  offColor="#f44336" 
                                  onColor="#4caf50" 
                                  offHandleColor="#fff" 
                                  onHandleColor="#fff" 
                                />
                                </td>
                                <td>
                                <FiEdit
                                    style={{ cursor: 'pointer', color: '#4caf50' }} 
                                    onClick={() => handleRowClick(item._id)}
                                  />
                                    {/* <FiTrash
                                      style={{ cursor: 'pointer', color: '#f44336' }} // Styling for the Delete icon
                                      onClick={() => togglePopUp(item._id)} // Function to handle the delete action
                                    /> */}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="4">No data found</td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      <div className="all_pagination">
                      <p>Show {((currentPage - 1) * 10) + 1} to {Math.min(currentPage * 10, totalItems)} of {totalItems}</p>
                      
                      <div className="table_pagination_sales_order">
                        <Pagination
                          count={Math.ceil(totalItems / 10)}
                          page={currentPage}
                          onChange={(event, page) =>
                            handlePageChange(event, page)
                          }
                        />
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ProductDeletePopUp
        popUpOpen={popUpOpen}
        togglePopUp={togglePopUp}
        itemIdToDelete={itemIdToDelete}
      />
     <ProductOutPopUp
       popUpOpenchange={popUpOpenchange}
       togglePopUpchange={togglePopUpchange}
       itemIdToChange={itemIdToChange}
       status={itemChange}
      />
    </div>
  );
}

export default ListProductName;
